<template>
  <div class="container">
    <div class="tipWrap">成绩排名与掉金币成正相关，但并不完全相等。</div>
    <div class="top3Wrap">
      <div class="itemWrap">
        <div class="headWrap">
          <img class="hat" src="@/assets/images/rank/second.png" alt />
          <img
            class="avatar"
            v-if="top3User[1]"
            :src="top3User[1].avatar"
            alt
          />
          <img
            class="avatar"
            v-else
            src="@/assets/images/memberCenter.png"
            alt
          />
        </div>
        <div class="infoWrap">
          <div v-if="top3User[1]" class="nickname">
            {{ top3User[1].nickName }}
          </div>
          <div v-else class="nickname">无人上榜</div>
          <!--          <div class="jobWrap">-->
          <!--            <div class="label">男方</div>-->
          <!--            <div class="detail">大舅哥</div>-->
          <!--          </div>-->
          <div class="scoreLabel">分数</div>
          <div class="score" v-if="top3User[1]">{{ top3User[1].score }}</div>
          <div class="score" v-else>0</div>
        </div>
      </div>
      <div
        class="itemWrap"
        v-if="top3User[0]"
        style="margin: 0 10px; position: relative; top: -33px"
      >
        <div class="headWrap">
          <img class="hat" src="@/assets/images/rank/top.png" alt />
          <img
            class="avatar"
            v-if="top3User[0]"
            :src="top3User[0].avatar"
            alt
          />
          <img
            class="avatar"
            v-else
            src="@/assets/images/memberCenter.png"
            alt
          />
        </div>
        <div class="infoWrap">
          <div v-if="top3User[0]" class="nickname">
            {{ top3User[0].nickName }}
          </div>
          <div v-else class="nickname">无人上榜</div>
          <div class="jobWrap">
            <div class="label">男方</div>
            <div class="detail">大舅哥</div>
          </div>
          <div class="scoreLabel">分数</div>
          <div class="score" v-if="top3User[0]">{{ top3User[0].score }}</div>
          <div class="score" v-else>0</div>
        </div>
      </div>
      <div class="itemWrap">
        <div class="headWrap">
          <img class="hat" src="@/assets/images/rank/third.png" alt />
          <img
            class="avatar"
            v-if="top3User[2]"
            :src="top3User[2].avatar"
            alt
          />
          <img
            class="avatar"
            v-else
            src="@/assets/images/memberCenter.png"
            alt
          />
        </div>
        <div class="infoWrap">
          <div v-if="top3User[2]" class="nickname">
            {{ top3User[2].nickName }}
          </div>
          <div v-else class="nickname">无人上榜</div>
          <div class="jobWrap">
            <div class="label">男方</div>
            <div class="detail">大舅哥</div>
          </div>
          <div class="scoreLabel">分数</div>
          <div class="score" v-if="top3User[2]">{{ top3User[2].score }}</div>
          <div class="score" v-else>0</div>
        </div>
      </div>
    </div>
    <div class="rankWrap">
      <div class="rankItem" v-for="(item, index) in leftUser" :key="index">
        <div class="rank">{{ index + 4 }}</div>
        <img :src="item.avatar" />
        <div class="userInfo">
          <span class="nickname">{{ item.nickName }}</span>
          <div class="jobWrap">
            <div class="label">男方</div>
            <div class="detail">大舅哥</div>
          </div>
        </div>
        <div class="scoreInfo">
          <div class="scoreLabel">分数</div>
          <div class="score">{{ item.score }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { Toast } from "vant";
export default {
  name: "GameRank",
  computed: {
    ...mapState({
      liveGame: (state) => state.liveGame,
    }),
    top3User() {
      if (this.liveGameUserList.length <= 3) {
        return this.liveGameUserList;
      } else {
        return this.liveGameUserList.slice(0, 3);
      }
    },
    leftUser() {
      if (this.liveGameUserList.length <= 3) {
        return [];
      } else {
        const len = this.liveGameUserList.length;
        return this.liveGameUserList.slice(3, len);
      }
    },
  },
  data: function () {
    return {
      liveGameUserList: [],
    };
  },
  created() {
    this.getRankList();
  },
  mounted() {
    this.$Bus.$on("goHome", () => {
      console.log("退出。。。。。。。。");
      this.$store.commit("live/closeGameReady");
      this.$router.replace({ path: "/" });
    });
    this.listenBack();
  },
  methods: {
    listenBack() {
      const that = this;
      window.addEventListener(
        "popstate",
        function (e) {
          console.log("监听后退" + e);
          that.$Bus.$emit("goHome");
        },
        false
      );
    },
    getRankList() {
      const toast = Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "加载中...",
      });
      // const id = '7cdd4011f9bd6c462b723be2901b92c7';
      // this.liveGame.id = '7cdd4011f9bd6c462b723be2901b92c7';
      this.$http
        .get("/dlr-game/game/getLiveGameUserList", {
          params: { id: this.liveGame.id },
        })
        .then((res) => {
          this.$Bus.$emit("refreshCoin");
          this.liveGameUserList = res.data;
        })
        .finally(() => {
          toast.clear();
        });
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 100vw;
  //height: calc(100vh + 197px);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-image: url("~@/assets/images/rank/bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 37px 26px;
  margin-bottom: 197px;
  .tipWrap {
    width: 668px;
    height: 54px;
    background: rgba(213, 239, 207, 0.39);
    border-radius: 10px;
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 24px;
    color: #094609;
    text-align: center;
    line-height: 54px;
  }
  .top3Wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 197px;
    .itemWrap {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      width: 222px;
      //height: 267px;
      background-color: #fff;
      //background: rgba(255,255,255,0.39);
      box-shadow: 1px 1px 21px rgba(4, 0, 0, 0.1);
      border-radius: 18px;
      .headWrap {
        position: relative;
        //border: 1px solid #f4b409;
        top: -53px;
        .avatar {
          width: 120px;
          height: 120px;
          border-radius: 50%;
          border: 1px solid #f4b409;
        }
        .hat {
          position: absolute;
          top: -10px;
          left: -10px;
          width: 54px;
          height: 48px;
        }
      }
      .infoWrap {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        //background-color: #52A7F7;
        position: relative;
        top: -33px;
        .nickname {
          font-size: 30px;
          color: #222222;
          margin-top: 8px;
        }
        .jobWrap {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 100%;
          margin-top: 26px;
          //background-color: #d33;
          .label {
            background: linear-gradient(360deg, #52a7f7 0%, #52c4f4 100%);
            border-radius: 6px;
            color: #fff;
            font-weight: 500;
            font-size: 15px;
            width: 72px;
            height: 30px;
            text-align: center;
            line-height: 30px;
            margin-right: 16px;
          }
          .detail {
            width: 82px;
            height: 30px;
            //background: rgba(255,205,44,0.39);
            background-color: #ffcd2c;
            border-radius: 6px;
            font-size: 18px;
            color: #ffffff;
            text-align: center;
            line-height: 30px;
          }
        }
        .scoreLabel {
          font-size: 24px;
          color: #fd9703;
          margin-top: 11px;
        }
        .score {
          font-size: 26px;
          color: #fd9703;
          margin-top: 7px;
        }
      }
    }
  }
  .rankWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 6px;
    .rankItem {
      margin-top: 26px;
      width: 690px;
      height: 148px;
      background-color: #fff;
      box-shadow: 1px 1px 21px rgba(4, 0, 0, 0.1);
      border-radius: 18px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      .rank {
        background-image: url("~@/assets/images/rank/bg_rank_no.png");
        background-size: 100% 100%;
        width: 42px;
        height: 48px;
        text-align: center;
        line-height: 48px;
        font-size: 28px;
        color: #5b6dbb;
      }
      img {
        width: 86px;
        height: 86px;
        border-radius: 50%;
      }
      .userInfo {
        display: flex;
        flex-direction: column;
        .nickname {
          font-size: 28px;
          color: #676767;
        }
        .jobWrap {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 100%;
          margin-top: 26px;
          //background-color: #d33;
          .label {
            background: linear-gradient(360deg, #52a7f7 0%, #52c4f4 100%);
            border-radius: 6px;
            color: #fff;
            font-weight: 500;
            font-size: 15px;
            width: 72px;
            height: 30px;
            text-align: center;
            line-height: 30px;
            margin-right: 16px;
          }
          .detail {
            width: 82px;
            height: 30px;
            //background: rgba(255,205,44,0.39);
            background-color: #ffcd2c;
            border-radius: 6px;
            font-size: 18px;
            color: #ffffff;
            text-align: center;
            line-height: 30px;
          }
        }
      }
      .scoreLabel {
        font-size: 24px;
        color: #fd9703;
        margin-top: 11px;
      }
      .score {
        font-size: 26px;
        color: #fd9703;
        margin-top: 7px;
      }
    }
  }
}
</style>
